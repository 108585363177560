import 'babel-polyfill'
import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import adapter from "./util/adapter";
import axios from 'axios'
Vue.prototype.$axios = axios
Vue.use(adapter);
Vue.config.productionTip = false;

import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper, /* { 全局组件的默认选项 } */)

Vue.prototype.$axios = axios

import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad, {
  preLoad: 1.3,
  error: "/static/error.png",
  loading: "/static/loading.png",
  attempt: 1,
})

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
